<template>
  <div class="newsbox">
    <div class="top common">
      <img src="../assets/img/产品.jpg" alt="" />
      <img
        src="../assets/img/arrow.gif"
        class="arrow"
        @click="gotoself('.content')"
        alt=""
      />
      <div class="topleft">
        <Nav />
      </div>
    </div>
    <div class="common content">
      <div class="title">{{ list[this.now].title }}</div>
      <div class="mainpic common">
        <van-icon
          name="arrow-left"
          size="45"
          color="#fff"
          class="arrow"
          @click="$refs.swiper.prev()"
        />
        <van-icon
          name="arrow"
          size="45"
          color="#fff"
          class="arrow"
          style="right:0"
          @click="$refs.swiper.next()"
        />
        <van-swipe
          style="height: 458px;"
          :show-indicators="false"
          :touchable="false"
          ref="swiper"
        >
          <van-swipe-item
            class="contentitem"
            v-for="(item, index) in list[this.now].swiperList"
            :key="index"
          >
            <div class="imgbox">
              <img :src="item.url" alt="" />
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="allpic common">
        <div
          class="contentitem"
          v-for="(item, index) in list[this.now].list"
          :key="index"
          @click="$refs.swiper.swipeTo(index)"
        >
          <div class="imgbox">
            <img :src="item.url" alt="" />
          </div>
          <div class="des">
            <div>{{ item.dsc1 }}</div>
            <div>{{ item.dsc2 }}</div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
import "@vant/touch-emulator";
export default {
  components: {
    Footer,
    Nav
  },
  data() {
    return {
      list: [
        {
          title: "7P普洱生茶",
          list: [
            {
              dsc1: "7P生茶",
              dsc2: "128g饼茶",
              url:
                "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/产品3级页面/shengcha01.jpg"
            },
            {
              dsc1: "7P生茶",
              dsc2: "4盒*32g小沱",
              url:
                "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/产品3级页面/shengcha02.jpg"
            }
          ],
          swiperList: [
            { url: require("../assets/img/7p滑动图01.jpg") },
            { url: require("../assets/img/7p滑动图02.jpg") }
          ]
        },
        {
          title: "7T普洱熟茶",
          list: [
            {
              dsc1: "7T熟茶",
              dsc2: "128g饼茶",
              url:
                "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/产品3级页面/shucha01.jpg"
            },
            {
              dsc1: "7T熟茶",
              dsc2: "4盒*32g小沱",
              url:
                "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/产品3级页面/shucha02.jpg"
            }
          ],
          swiperList: [
            { url: require("../assets/img/7T滑动图01.jpg") },
            { url: require("../assets/img/7T滑动图02.jpg") }
          ]
        },
        {
          title: "7A古树白茶",
          list: [
            {
              dsc1: "7A白茶",
              dsc2: "128g饼茶",
              url:
                "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/产品3级页面/baicha01.jpg"
            },
            {
              dsc1: "7A白茶",
              dsc2: "4盒*32g小沱",
              url:
                "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/产品3级页面/baicha02.jpg"
            },
            {
              dsc1: "7A白茶",
              dsc2: "128g饼茶",
              url:
                "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/产品3级页面/baicha03.jpg"
            }
          ],
          swiperList: [
            { url: require("../assets/img/7A滑动图01.jpg") },
            { url: require("../assets/img/7A滑动图02.jpg") },
            { url: require("../assets/img/7A滑动图03.jpg") }
          ]
        },
        {
          title: "7C古树红茶",
          list: [
            {
              dsc1: "7C红茶",
              dsc2: "128g饼茶",
              url:
                "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/产品3级页面/hongcha01.jpeg"
            },
            {
              dsc1: "7C红茶",
              dsc2: "4盒*32g小沱",
              url:
                "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/产品3级页面/hongcha02.jpeg"
            }
          ],
          swiperList: [
            { url: require("../assets/img/7C滑动图01.jpg") },
            { url: require("../assets/img/7C滑动图02.jpg") }
          ]
        },
        {
          title: "生来不凡",
          list: [
            {
              dsc1: "生来不凡",
              dsc2: "256g饼茶",
              url: require("../assets/img/shenglai1-1.png")
            },
            {
              dsc1: "生来不凡",
              dsc2: "64g饼茶",
              url: require("../assets/img/shenglai2-1.png")
            }
          ],
          swiperList: [
            { url: require("../assets/img/shenglai1.png") },
            { url: require("../assets/img/shenglai2.jpg") }
          ]
        }
      ],
      now: 0
    };
  },
  mounted() {
    this.now = this.$route.query.index * 1;
  },
  methods: {
    gotoself(key) {
      this.$el.querySelector(key).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start" // 上边框与视窗顶部平齐
      });
    }
  }
};
</script>

<style lang="less" scoped>
.common {
  margin: 0 auto;
}
.top {
  height: 1080px;
  position: relative;
  margin-bottom: 38px;
  img {
    width: 100%;
    height: 100%;
  }
  .arrow {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50px;
    height: 100px;
    transform: translate(-50%, -100%);
    cursor: pointer;
  }
  .topleft {
    position: absolute;
    height: 100%;
    width: 415px;
    background: rgba(0, 0, 0, 0.1) no-repeat center;
    left: 0;
    top: 0;
    // background-image: url("https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/logo.png");
    // background-size: 135px 190px;
  }
  .toptitle {
    position: absolute;
    top: 470px;
    // width: 100%;
    height: 310px;
    color: #ffffff;
    left: 530px;
    .topcn {
      font-size: 37px;
      // margin-left: 415px;
      // margin-top: 54px;
      // text-align: center;
      font-weight: 800;
    }
    .topen {
      font-size: 37px;
      // margin-left: 415px;
      // text-align: center;
      font-weight: 200;
    }
  }
}
.content {
  width: 70%;
  .title {
    font-size: 30px;
    padding-top: 38px;
    text-align: center;
    padding-bottom: 28px;
  }
  .mainpic {
    height: 458px;
    width: 80%;
    margin-bottom: 28px;
    // display: flex;
    // justify-content: space-between;
    position: relative;
    .arrow {
      position: absolute;
      z-index: 9;
      margin-top: 185px;
      cursor: pointer;
    }
    .contentitem {
      width: 100%;
      height: 100%;
      // float: left;
      .imgbox {
        width: 100%;
        height: 458px;
        overflow: hidden;
        img {
          width: 100%;
          height: 458px;
          transition: 1s;
        }
        img:hover {
          transform: scale(1.1);
        }
      }

      .des {
        background: #000;
        color: #edb55b;
        font-size: 12px;
        display: flex;
        height: 34.5px;
        justify-content: space-between;
        line-height: 34.5px;
        padding: 0 16px;
      }
    }
  }
  .allpic {
    margin-bottom: 33px;
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    .contentitem {
      width: 329px;
      // float: left;
      .imgbox {
        width: 100%;
        height: 224px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          transition: 1s;
        }
        img:hover {
          transform: scale(1.1);
        }
      }

      .des {
        background: #000;
        color: #edb55b;
        font-size: 24px;
        display: flex;
        height: 80px;
        justify-content: space-between;
        line-height: 80px;
        padding: 0 25px;
      }
    }
  }
}
</style>
